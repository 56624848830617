import { Card } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function StatusCardsOnline({
  product,
  productTransaction,
  paymentTransaction,
  customer,
  startDate,
  endDate,
  loading,
}) {
  const [orderStack, setOderStack] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [orderCompleted, setOrderCompleted] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  console.log('productTransaction', productTransaction);
  console.log('startDate', startDate);
  console.log('endDate', endDate);

  const newPaymentTransaction = _.filter(
    paymentTransaction?.rows,
    // eslint-disable-next-line consistent-return
    (eachPaymentTransaction) => {
      if (eachPaymentTransaction?.status_order === 'PRODUCT_COMPLETE') {
        return eachPaymentTransaction;
      }
    },
  );

  console.log('paymentTransaction', newPaymentTransaction);

  // console.log('dataStack', dataStack);

  useEffect(() => {
    setTotalPrice([]);

    // find order stack
    const stackData = [];
    _.map(newPaymentTransaction, (log) => {
      _.map(log.order_id.orders, (order) => {
        const Data = {
          order,
          date: log.updatedAt,
        };
        stackData.push(Data);
      });
    });
    // console.log('stackData', stackData);
    setOderStack(stackData);

    // find order completed

    const orderStackCompleted = _.filter(
      newPaymentTransaction,
      (eachPayment) => eachPayment.status_order === 'PRODUCT_COMPLETE',
    );

    // console.log('orderStackCompleted', orderStackCompleted);
    setOrderCompleted(orderStackCompleted);

    // find total price of product
    const paymentPriceArr = _.map(
      newPaymentTransaction,
      // eslint-disable-next-line consistent-return
      (eachPayment) => {
        if (eachPayment.status_order === 'PRODUCT_COMPLETE') {
          return eachPayment.amount;
        }
        return 0;
      },
    );
    const totalPriceOfProduct = _.sum(paymentPriceArr);
    setTotalPrice(totalPriceOfProduct);
    return () => {};
  }, [newPaymentTransaction.length]);

  if (!loading) {
    return (
      <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">รายได้รวมทั้งหมด</div>
              <div className="flex">
                <i className={'fas fa-coins fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {' '}
                  {`${(totalPrice - deliveryPrice).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`}{' '}
                  {'บาท'}{' '}
                  <a className="text-sm">( ค่าส่งสินค้า {deliveryPrice} บาท)</a>
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนออเดอร์ที่สำเร็จเเล้ว</div>
              <div className="flex">
                <i className={'fas fa-box-open fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {_.size(orderCompleted)} รายการ
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
              <div className="flex">
                <i className={'far fa-address-card fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {customer?.rows?.length}
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนออเดอร์ทั้งหมด</div>
              <div className="flex">
                <i className={'far fa-clipboard fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {paymentTransaction?.rows?.length} รายการ
                </h1>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return (
    <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนเงินที่ดำเนินการ</div>
            <div className="flex">
              <i className={'fas fa-coins fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">กำลังคำนวณ...</h1>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนสินค้าที่ดำเนินการ</div>
            <div className="flex">
              <i className={'fas fa-box-open fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">กำลังคำนวณ...</h1>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
            <div className="flex">
              <i className={'far fa-address-card fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">
                {customer?.rows?.length}
              </h1>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนออเดอร์ทั้งหมด</div>
            <div className="flex">
              <i className={'far fa-clipboard fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">กำลังคำนวณ...</h1>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

StatusCardsOnline.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  paymentTransaction: PropTypes.object,
  title: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
};

StatusCardsOnline.defaultProps = {
  product: null,
  productTransaction: null,
  paymentTransaction: null,
  title: null,
  count: null,
  icon: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default StatusCardsOnline;
