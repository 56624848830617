import { Card, Skeleton } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
// import dayjs from 'dayjs';
// import Loading from '../Loading';

export function StatusCards({
  product,
  productTransaction,
  customer,
  loading,
  periodText,
}) {
  console.log('productTransaction', productTransaction);

  const NewProductOut = _.filter(
    product,
    (each) => each?.number_of_sales_offline_out !== 0,
  );

  const NewProductIn = _.filter(
    product,
    (each) => each?.number_of_sales_offline_in !== 0,
  );

  // console.log('NewProductOut', NewProductOut);
  // console.log('NewProductIn', NewProductIn);

  const countQuantityOut = _.sumBy(
    NewProductOut,
    (each) => each?.number_of_sales_offline_out,
  );

  const countPriceOut = _.sumBy(
    NewProductOut,
    (each) => each?.total_price_offline_out,
  );

  const countQuantityIn = _.sumBy(
    NewProductIn,
    (each) => each?.number_of_sales_offline_in,
  );

  const countPriceIn = _.sumBy(
    NewProductIn,
    (each) => each?.total_price_offline_in,
  );

  console.log('countPriceIn', countPriceIn);
  // console.log('countQuantity', countQuantity);

  if (!loading) {
    return (
      <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">
                {periodText === 'ออก' ||
                periodText === 'pos' ||
                periodText === 'online' ? (
                  <>รายได้ (บาท)</>
                ) : (
                  <>รายจ่าย (บาท)</>
                )}
              </div>
              <div className="flex">
                <i className={'fas fa-coins fa-2x'} />
                {periodText === 'ออก' ? (
                  <h1 className="pl-2 my-auto text-2xl">
                    {' '}
                    {`${parseFloat(countPriceOut, 10 || 0, 10).toLocaleString(
                      undefined,
                    )}`}
                  </h1>
                ) : (
                  <h1 className="pl-2 my-auto text-2xl">
                    {' '}
                    {`${parseFloat(countPriceIn, 10 || 0, 10).toLocaleString(
                      undefined,
                    )}`}
                  </h1>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">
                {periodText === 'ออก' ? (
                  <>จำนวนสินค้าที่ขายแล้ว</>
                ) : (
                  <>จำนวนสินค้าที่นำเข้า</>
                )}
              </div>
              <div className="flex">
                <i className={'fas fa-box-open fa-2x'} />
                {periodText === 'เข้า' && (
                  <h1 className="pl-2 my-auto text-2xl">
                    {`${parseFloat(countQuantityIn, 10 || 0, 10).toLocaleString(
                      undefined,
                    )}`}{' '}
                  </h1>
                )}{' '}
                {periodText === 'ออก' && (
                  <h1 className="pl-2 my-auto text-2xl">
                    {`${parseFloat(
                      countQuantityOut,
                      10 || 0,
                      10,
                    ).toLocaleString(undefined)}`}{' '}
                  </h1>
                )}
                {periodText === 'pos' && (
                  <h1 className="pl-2 my-auto text-2xl">
                    {`${parseFloat(
                      countQuantityOut,
                      10 || 0,
                      10,
                    ).toLocaleString(undefined)}`}{' '}
                  </h1>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
              <div className="flex">
                <i className={'far fa-address-card fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {customer?.rows?.length}
                </h1>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Card className="p-6">
            <div>
              <div className="pb-2 text-xl">จำนวนออเดอร์ทั้งหมด</div>
              <div className="flex">
                <i className={'far fa-clipboard fa-2x'} />
                <h1 className="pl-2 my-auto text-2xl">
                  {productTransaction?.rows?.length}
                </h1>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return (
    <div className="grid  md:grid-cols-2  gap-x-2 gap-y-2 pb-2">
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">รายได้ (บาท)</div>
            <div className="flex">
              <i className={'fas fa-coins fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนสินค้าที่ขายแล้ว</div>
            <div className="flex">
              <i className={'fas fa-box-open fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนลูกค้าทั้งหมด</div>
            <div className="flex">
              <i className={'far fa-address-card fa-2x'} />
              <h1 className="pl-2 my-auto text-2xl">
                {customer?.rows?.length}
              </h1>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="p-6">
          <div>
            <div className="pb-2 text-xl">จำนวนออเดอร์ทั้งหมด</div>
            <div className="flex">
              <i className={'far fa-clipboard fa-2x'} />
              <div className="pl-2 my-auto">
                <Skeleton variant="rectangular" width={210} height={20} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

StatusCards.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  title: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  periodText: PropTypes.string,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
};

StatusCards.defaultProps = {
  product: null,
  productTransaction: null,
  title: null,
  count: null,
  icon: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default StatusCards;
